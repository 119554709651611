import React, { useEffect } from "react";
import Sections from "components/sections/sections";
import Seo from "components/Seo";
import ErrorPage from "next/error";
import { useRouter } from "next/router";
import { fetchAPI, getGlobalData } from "../utils/api";
import { getLocalizedPaths } from "../utils/localize";
import { Mixpanel } from "utils/mixpanel";

const DynamicPage = ({
  sections,
  metadata,
  preview,
  global,
  pageContext,
  pageData,
}) => {
  const router = useRouter();

  // Check if required data was provided
  if (!router.isFallback && !sections?.length) {
    return <ErrorPage statusCode={404} />;
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>;
  }

  // Merge default site SEO settings with page specific SEO settings
  if (metadata.shareImage?.data == null) {
    delete metadata.shareImage;
  }
  const metadataWithDefaults = {
    ...global.attributes.metadata,
    ...metadata,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const trackPageHit = () => {
      Mixpanel.track(pageData.title, { localeInfo: pageContext, pageData });
    };
    trackPageHit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* add meta tags for seo */}
      <Seo metadata={metadataWithDefaults} />
      {/* Display content Sections */}
      <Sections sections={JSON.parse(sections)} preview={preview} />
    </>
  );
};

export async function getStaticProps(context) {
  const { locale, locales, defaultLocale, preview = null } = context;

  const globalLocale = await getGlobalData(locale);

  // console.log(
  //   "globalLocale------->",
  //   globalLocale?.attributes?.navbar?.navLinks[0].children.data
  // );
  // Fetch pages with homepage set to true. Include drafts if preview mode is on
  const page = await fetchAPI("/pages", {
    filters: {
      homepage: {
        $eq: true,
      },
    },
    populate: "deep",
    fields: "*",
    locale,
    preview,
  });

  if (page.data == null || page.data.length === 0) {
    // Giving the page no props will trigger a 404 page
    return { props: {} };
  }

  //   Select the first page returned by the API as the page to display
  const pageData = page.data[0];

  // We have the required page data, pass it to the page component
  const {
    contentSections,
    metadata,
    localizations,
    slug,
    full_slug,
    ...pageExtraData
  } = pageData.attributes;

  const pageContext = {
    locale,
    locales,
    defaultLocale,
    slug,
    localizations,
    full_slug,
  };

  const localizedPaths = getLocalizedPaths(pageContext);

  return {
    props: {
      preview,
      sections: JSON.stringify(contentSections),
      metadata,
      global: globalLocale,
      pageData: { ...pageExtraData },
      pageContext: {
        ...pageContext,
        localizedPaths,
      },
    },
    revalidate: 10,
  };
}

export default DynamicPage;
